.backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   z-index: 10;
   background: rgba(0, 0, 0, 0.75);
}

.modal {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   justify-content: center;
   z-index: 100;
   overflow: hidden;
   padding-inline: 3rem;
   padding-top: 1.5rem;
   padding-bottom: 4rem;
   background: #F28123;
   box-shadow: 0 0 20px #232323;
   border-radius: 0.7rem;
   opacity: 1;
   animation-name: fadeInOpacity;
   animation-iteration-count: 1;
   animation-timing-function: ease-in;
   animation-duration: 0.3s;
}

.close {
   position: absolute;
   right: 0.7rem;
   top: 0.2rem;
   color: #fff;
   background-color: rgba(255, 255, 255, 0);
   font-weight: 700;
   border: none;
}

.text {
   color: #fff;
   padding-top: 1rem;
   padding-bottom: 0.5rem;
}

.btn {
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 0%);
   margin-bottom: 0.5rem;
}

.inputStyle {
   background-color: #F28123;
   border-color: #3b3b3b;
   border-radius: 7px;
   color: #fff;
   padding-right: 7px;
}

.inputStyle::placeholder {
   color: rgba(255, 255, 255, 0.707);
}

@keyframes fadeInOpacity {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}