.modal {
   position: fixed;
   bottom: 1rem;
   left: 1.5rem;
   z-index: 100;
   overflow: hidden;
   padding: 1rem;
   background: #F28123;
   border-radius: 0.7rem;
   opacity: 1;
   animation-name: fadeInOpacity;
   animation-iteration-count: 1;
   animation-timing-function: ease-in;
   animation-duration: 0.3s;
}

.close {
   position: absolute;
   right: 0.7rem;
   top: 0.2rem;
   color: #fff;
   background-color: rgba(255, 255, 255, 0);
   font-weight: 700;
   border: none;
}

.text {
   color: #fff;
   padding-top: 2rem;
}

@keyframes fadeInOpacity {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@media only screen and (max-width: 600px) {
   .modal {
      left: 0;
      margin-inline: 0.5rem;
   }
}